<template>
  <AppBanner v-if="$auth.check()" />
  <global-header v-if="$auth.check()"></global-header>
  <div class="min-h-screen">
    <global-breadcrumbs v-if="$auth.check()" />
    <router-view :key="$route.fullPath" />
  </div>
  <global-footer v-if="$auth.check()"></global-footer>
  <notification-dialog
    v-if="notification.show"
    :content="notification.message"
    :icon="notification.icon"
    @close="notification.show = false"
  ></notification-dialog>

  <download-banner
    v-model="banner"
    :handleCloseBanner="handleCloseBanner"
    :link="appleStore"
  />
  <notification-component />
  <div id="modal_dialog_teleport"></div>
</template>
<script setup>
import { inject, onMounted, ref } from "vue";
import Echo from "laravel-echo";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import initializeAIChat from "@/composable/ai_chat_widget";
import DownloadBanner from "./components/banner/DownloadBanner.vue";
import { isMobile } from "vue-device-detect";
import { useRoute } from "vue-router";
import { usePusherStore } from "@/stores/pusher";
window.Pusher = require("pusher-js");

const auth = useAuth();
const pusherConfig = usePusherStore();
const axios = inject("axios");
const pusherKey = ref("");
const banner = ref(false);
const appleStore = ref("");
const route = useRoute();

const showBannerForMobile = () => {
  const hasViewBanner = localStorage.getItem("has_view_banner");

  if (
    isMobile &&
    !hasViewBanner &&
    !window.MSStream
  ) {
    banner.value = false;
  }
};

const handleCloseBanner = () => {
  localStorage.setItem("has_view_banner", 1);
  banner.value = false;
};

onMounted(async () => {
  showBannerForMobile();

  await setEnv();
  setupPusher();

  setTimeout(function () {
    if (auth.check()) {
      initializeAIChat(auth.user());
    }
  }, 2000);
});

const setEnv = async () => {
  await axios.get("/api/get-env").then(({ data }) => {
    pusherConfig.setPusher(data);
    pusherKey.value = {
      pusher_cluster: data.pusher_cluster,
      pusher_key: data.pusher_key,
    };
    appleStore.value = data.apple_store;
  });
};

function setupPusher() {
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: pusherKey.value.pusher_key,
    cluster: pusherKey.value.pusher_cluster,
    forceTLS: true,
    authEndpoint: "/broadcasting/auth",
    encrypted: true,
    auth: {
      headers: {
        Authorization: `Bearer ${auth.token()}`,
      },
    },
  });

  ssoLogoutListener();
}

function logout() {
  axios.get("/api/okta/logout").then(({ data }) => {
    auth.logout();
    localStorage.removeItem("nextUrl");
    window.location.href = data;
  });
}

function goBack() {
  axios
    .get("/api/okta/get-logout-link", {
      params: {
        user_central_id: route.query.user_central_id,
      },
    })
    .then(({ data }) => {
      window.location.href = data;
    });
}

function ssoLogoutListener() {
  window.Echo.channel("sso-logout").listen("SSOLogout", (e) => {
    let centralId = auth.user()
      ? auth.user().central_id
      : route.query.user_central_id;

    if (centralId != e.user_central_id) {
      return;
    }

    if (route.query.user_central_id) {
      goBack();
      return;
    }

    logout();
  });
}
</script>

<script>
import GlobalHeader from "./components/layout/GlobalHeader.vue";
import GlobalFooter from "./components/layout/GlobalFooter.vue";
import NotificationDialog from "@/components/partials/NotificationDialog";
import eventBus from "./plugins/eventBus";
import { useCookies } from "vue3-cookies";
import GlobalBreadcrumbs from "./components/layout/GlobalBreadcrumbs.vue";
import NotificationComponent from "@/components/partials/NotificationComponent.vue";
import AppBanner from "./components/layout/AppBanner.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    NotificationComponent,
    GlobalHeader,
    GlobalFooter,
    NotificationDialog,
    GlobalBreadcrumbs,
    AppBanner,
  },
  data() {
    return {
      notification: {
        message: "",
        show: false,
        icon: true,
      },
    };
  },
  mounted() {
    eventBus.$on("NOTIFICATION_DIALOG", (data) => {
      this.notification = {
        show: true,
        message: data.message,
        icon: data.icon,
      };
    });
  },
};
</script>

<style>
:root {
  --el-color-primary: #939495 !important;
  --el-button--primary: #939495 !important;
}

.el-button--primary:hover {
  background-color: #939495 !important;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #eaecf0;
  border-radius: 4px;
  margin: 10px 0;
}
::-webkit-scrollbar-thumb {
  background: #667085;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #667085;
}
.c2::-webkit-scrollbar-track {
  background-color: "#101828";
}
.dp__pm_am_button {
  color: black !important;
}
.el-select-v2__wrapper {
  padding: 5px !important;
}
.el-dialog {
  border-radius: 20px !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 100% !important;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-range-editor.el-input__wrapper {
  padding: 5px !important;
  width: 100% !important;
}
.el-select-v2__tags-text {
  max-width: 150px !important;
}
.el-select-v2__selection {
  width: 95% !important;
}

.el-popover.el-popper {
  z-index: 1 !important;
}

.is-pure {
  z-index: 4003 !important;
}

#si_frame {
  height: 100% !important;
}

#si-wrapper .silc-btn {
  bottom: 10px !important;
  height: 60px !important;
  width: 60px !important;
  right: 140px !important;
}
</style>
